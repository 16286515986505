/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import moduleBranch from './branchStore/moduleBranch.js'
import moduleStaff from './staffStore/moduleStaff.js'
import moduleAsset from './assetStore/moduleAsset.js'
import modulePurchaseOrder from './purchaseOrderStore/modulePurchaseOrder.js'
import moduleInward from './inwardStore/moduleInward.js'
import moduleTransfer from './transferStore/moduleTransfer.js'
import moduleAssetCategory from './assetCategoryStore/moduleAssetCategory.js'
import moduleAssetType from './assetTypeStore/moduleAssetType'
import moduleCategorySub from './categorySubStore/moduleCategorySub'
import moduleState from './stateStore/moduleState'
import moduleDistrict from './districtStore/moduleDistrict'
import moduleCity from './cityStore/moduleCity'
import moduleAccessory from './accessoryStore/moduleAccessory.js'
import moduleGallery from './galleryStore/moduleGallery.js'
import moduleGoodsReceiveNote from './goodsReceiveNoteStore/moduleGoodsReceiveNote.js'

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    branchStore: moduleBranch,
    staffStore: moduleStaff,
    assetStore: moduleAsset,
    purchaseOrderStore: modulePurchaseOrder,
    inwardStore: moduleInward,
    transferStore: moduleTransfer,
    assetCategoryStore: moduleAssetCategory,
    assetTypeStore: moduleAssetType,
    categorySubStore: moduleCategorySub,
    stateStore: moduleState,
    districtStore: moduleDistrict,
    cityStore: moduleCity,
    accessoryStore: moduleAccessory,
    galleryStore:moduleGallery,
    goodsReceiveNoteStore:moduleGoodsReceiveNote,
  },
  strict: process.env.NODE_ENV !== 'Production'
})
