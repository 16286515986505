/*=========================================================================================
  File Name: moduleBranch.js
  Description: AllAllCompanyParty Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleGoodsReceiveNoteState.js'
import mutations from './moduleGoodsReceiveNoteMutations.js'
import actions from './moduleGoodsReceiveNoteActions.js'
import getters from './moduleGoodsReceiveNoteGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}