<!-- =========================================================================================
    File Name: EmployeesAddNew.vue
    Description: Add new Employees
    ----------------------------------------------------------------------------------------
    Item Name: Vaagai Tecknowledge - Easy Admin
    Author: Web Team
    Author URL: http://www.vaagai.org.in/
========================================================================================== -->


<template>
  <div id="employee-create">
    <v-card>
      <v-app-bar
        :color="themeConfig.myPrimaryColor"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>Update Primary Branch</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt" v-if="CloseButtonFlag">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-row wrap v-if="StartupLoadingFlag">
          <v-col cols="12" md="4" v-for="n in 6" :key="n">
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-card
          elevation="6"
          outlined
          id="my-data-table"
          v-if="!StartupLoadingFlag"
        >
          <v-card-text>
            <v-text-field
              v-model="search"
              clearable
              flat
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Search"
            ></v-text-field>
            <br />
            <div style="height: 500px; overflow: auto"> 
              <v-data-iterator
                :items="BranchData"
                :search="search"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="item in props.items"
                      :key="item.BranchId"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                    >
                      <v-card @click.stop="submitForm(item)">
                        <v-card-text align="center">
                          <v-img
                            contain
                            width="100"
                            :src="item.AssetLogoPath"
                          />
                          <h4>{{ item.BranchCode }}</h4>
                          <h5>{{ item.BranchName }}</h5>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="CloseButtonFlag"
          @click.prevent="closePrompt"
          medium
          rounded
          outlined
          elevation="30"
          class="ml-3"
          :color="themeConfig.primary"
        >
          <v-icon> mdi-cancel </v-icon>
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/Common.vue";
import themeConfig from "@/themeConfig.js";

export default {
  mixins: [common],
  components: {},
  props: {},
  data() {
    return {
      ResultFlag: false,

      StartupLoadingFlag: false,
      themeConfig: themeConfig,
      valid1: false,
      LoadingFlag: false,
      activePrompt: true,

      CloseButtonFlag: false,

      search: "",

      BranchId: "",
      BranchData: [],

      ProductCategoryName: "",
      ProductCategoryRules: [(v) => !!v || "Category name is required"],

      counterDanger: false,

      recordObj: {},
      record: {},
    };
  },
  computed: {
    StatusFlag() {
      console.log("StatusFlag computed called");
      var StatusFlag = this.$store.getters["staffStore/StatusFlag"];
      console.log({ StatusFlag });
      return StatusFlag;
    },
  },
  watch: {
    StatusFlag: function () {
      console.log("watch StatusFlag called");

      var StatusFlag = this.StatusFlag;
      console.log({ StatusFlag });

      if (StatusFlag) {
        this.$emit("hideDialog", StatusFlag);
      }
    },
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.ResultFlag);
    },
    clearFields() {
      // clear all fileds in todo
      this.ProductCategoryName = "";

      this.ActiveStatus = "";
    },
    refreshPageData() {
      console.log("refreshPageData called");

      this.getBranchData();
    },
    submitForm(upload) {
      console.log("submitForm called");

      var headers = this.getApiHeaders();
      console.log({ headers });

      var server_url = companyConfig.apiURL;
      var add_url = server_url + "api/person/update-primary-branch";
      console.log({ add_url });
      // add records

      const loader = this.$loading.show({});

      var record = {};
      record.loader_flag = true;
      record.alert_flag = true;
      record.headers = headers;
      record.add_url = add_url;
      record.upload = upload;
      record.router = this.$router;
      record.loading = loader;
      record.notify = this.sweetAlert;
      record.session = this.$session;
      record.http = this.$http;

      this.$store.dispatch("staffStore/updatePrimaryBranch", record);
    },
    getBranchData() {
      console.log("getBranchData called");
      // var result = this.$refs.form1.validate();
      var result = true;
      console.log({ result });
      if (result) {
        this.BranchData = [];

        var upload = {
          UserInterface: 2,
          AppFlag: true,
        };
        console.log({ upload });

        const loader = this.$loading.show({});

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        var add_url = server_url + "api/branch/lists";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            loader.hide();

            flag = response.data.flag;
            output = response.data.output;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.BranchData = records;
              // thisIns.sweetAlert("success", output, true);
              // thisIns.closePrompt();
            } else {
              // thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message);
      }
    },
  },
  beforeMount() {
    if (!this.$session.exists()) {
      console.log("your session is expired");
      this.$router.push("/");
    } else {
      this.refreshPageData();
    }
  },
};
</script>
<style lang="scss">
#employee-create {
}
</style>